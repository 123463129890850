import React from "react"
import Layout from "../retech/components/layout/Layout"
import { Button, InputGroup } from "react-bootstrap"
import { Helmet } from "react-helmet"

import { Link, graphql } from "gatsby"
export default function ErrorPage({ data }) {
    const hero = data.hero.nodes
    const url = typeof window !== 'undefined' ? window.location.href : '';
    const urlimage = typeof window !== 'undefined' ? window.location.hostname : '';
    return (
        <Layout>
            <Helmet>
                <title>Retech | Thank you</title>

                <meta name="description" content="Thank you" />
                <meta name="keywords" content="Thank you" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={url} />
                <meta property="og:title" content="Thank you" />
                <meta property="og:description" content="Thank you" />
                <meta property="og:image" content={urlimage + "/logo.svg"} />
                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={url} />
                <meta property="twitter:title" content="Thank you" />
                <meta property="twitter:description" content="Thank you" />
                <meta property="twitter:image" content={urlimage + "/logo.svg"} />
            </Helmet>
            <div className="container-fluid px-0">


                <div
                    className="show_bg_desktop d-none d-sm-block"
                    style={{
                        height: "391px",
                        backgroundImage:
                            "linear-gradient(349.66deg,#1f2025 4.11%,rgba(31,32,37,.3) 18.52%,rgba(31,32,37,0) 36.03%,rgba(31,32,37,0) 70.01%),linear-gradient(86deg,#000 4.11%,transparent 70.01%),url(Homebg.png)",
                    }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 pt-5 mt-4 pr-5">
                                <h1 className="font-wight-boder">Thank you</h1>
                                <p className="text-white pb-4">
                                    Thank you for your inquiry. We'll get back in touch with you within 24 hours!
                                </p>

                            </div>
                        </div>
                    </div>
                </div>



                <div
                    className="show_bg_desktop  d-block d-sm-none"
                    style={{
                        height: "700px",
                        backgroundImage:
                            "linear-gradient(349.66deg,#1f2025 4.11%,rgba(31,32,37,.3) 18.52%,rgba(31,32,37,0) 36.03%,rgba(31,32,37,0) 70.01%),linear-gradient(86deg,#000 4.11%,transparent 70.01%),url(Homebg.png)",
                    }}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 pt-5 mt-4 pr-5">
                                <h1 className="font-wight-boder">Thank you</h1>
                                <p className="text-white pb-4">
                                    Thank you for your inquiry.We'll get back in touch with you within 24 hours!
                                </p>

                            </div>
                        </div>
                    </div>
                </div>




            </div>
        </Layout>
    )
}

export const query = graphql`
query{


hero:  allHeroJson {
    nodes {
      Hero404 {
        HeroSectionCTALink
        HeroSectionCTAText
        HeroSectionImage
        HeroSectionTitle
        HeroSectiondescription
      }
    }
  }
  metadata:  allMetadataJson(filter: {NotfoundMetaTitle: {ne: null}}) {
    nodes {
      NotfoundMetaDescription
      NotfoundMetaTitle
      Notfoundkeywords
    }
  }
}
`
